* {
  padding: 0;
  margin: 0;
}

.personalInfoContainer {
  background-color: white;
  padding: 1rem;
  padding-right: 1.3rem !important;
  width: 30vw;
  font-size: .93em;
}

.nameAndImageSection {
  display: flex;
  background: #f8faff;
  border: 1px solid rgba(156, 184, 255, .1);
  border-radius: 25px;
  margin-top: 1rem;
  padding: .8rem 1rem;
  align-items: center;
}

.candidateThumbnail {
  height: 43px;
  width: 43px;
}

.ProfileLogo {
  background: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: 0px 6px 44px rgba(185, 185, 218, .3);
  border-radius: 25px;
  padding: 1.5rem;
  margin-right: 1rem;
}

.candidateName {
  color: #16182F;
  font-weight: 400;
  font-size: 1.4em;
  line-height: 29px;
  margin-bottom: 0;
}

.candidateLocation {
  display: flex;
  align-items: center;
}

.candidateCity {
  color: #7A91AB;
  font-size: 1.1em;
  font-weight: 400;
}

.candidateResumeAndLinkedin {
  margin-top: .2rem;
  display: flex;
  align-items: center;

  span {
    cursor: pointer;
  }
}

.candidateDetailsOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  padding-bottom: 0rem;
}

.detailsOption {
  color: #7f889e;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 34px;
  cursor: pointer;
}

.detailsOption:hover {
  transform: scale(1.08);
  z-index: 4;
  transition: all .2s linear;
}

.detailsOptionSelected {
  color: #1C2640;
  font-weight: 700;
  font-size: 1.2em;
  line-height: 34px;
  cursor: pointer;
  border-bottom: 2px solid #FE5819;
}

.candidateDetails {
  background: #F8FAFF;
  border: 1px solid rgba(156, 184, 255, .1);
  border-radius: 30px;
  padding: 1.5rem 1.5rem;
  position: relative;
}

.lockedDetails {
  width: 100%;
  height: 100%;
}

.candidateEmailAndPhoneNoSection {
  margin-bottom: 1rem;
}

.candidateEmailDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.candidateEmailTxt {
  color: #5F6981;
  font-weight: 400;
  font-size: 1em;
  line-height: 23px;
  margin-left: .5rem;
}

.candidateEmail {
  color: #1C2640;
  font-size: 1.1em;
  font-weight: 500;
  line-height: 34px;
}

.candidatePhoneNoDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;  
}

.candidatePhoneTxt {
  color: #5F6981;
  font-weight: 400;
  font-size: 1em;
  line-height: 23px;
  margin-left: .5rem;  
}

.candidatePhoneNumber {
  color: #1C2640;
  font-size: 1.1em;
  font-weight: 500;
  line-height: 34px;  
}

.sectionSeprator {
  border-bottom: 1px solid #d9d6d6;
  margin: 1.3rem 0rem;
}

.summaryHeading {
  border-bottom: 0;
  color: #5F6981;
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
}

.summaryDescription {
  font-weight: 400;
  font-size: 1em;
  line-height: 26px;
  color: #1C2640;
}

.skillsHeading {
  color: #5F6981;
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
}

.candidateSkills {
  margin-top: .5rem;
  display: flex;
  flex-wrap: wrap;
}

.skill {
  background: #FFFFFF;
  border: 1px solid rgba(97, 150, 254, .3);
  border-radius: 20px;
  padding: .4rem .8rem;
  margin: .2rem .4rem;
}

.skill:hover {
  transform: scale(1.02);
  z-index: 4;
  box-shadow: rgba(0, 0, 0, .024) 0px 0px 0px 1px,
    rgba(0, 0, 0, .05) 0px 1px 0px 0px, rgba(0, 0, 0, .03) 0px 0px 8px 0px,
    rgba(0, 0, 0, .1) 0px 20px 30px 0px;
  transition: all .2s linear;
}

.candidateShareProfileContainer {
  background: #F8FAFF;
  border: 1px solid rgba(156, 184, 255, .1);
  border-radius: 30px;
  margin-top: 1rem;
  padding: 1.5rem 1.5rem;
}

.candidateShareProfileTxt {
  color: #080B2E;
  font-weight: 400;
  font-size: 1.25em;
}

.shareProfileDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shareProfileButton {
  display: flex;
  align-items: center;
  background-color: #6196FE;
  color: white;
  border-radius: 10px;
  padding: .7rem 1rem;
  border: 0;
}

.shareProfileButton:hover {
  box-shadow: 0 0 5px #6196FE; 
  transition: .2s ease-in-out;
}

.shareProfileButton:active {
  background-color: #3c7af8;
}

.shareProfileButton:focus {
  outline: none;
}

.defaultState {
  height: 460px;
}

.defaultStateContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 460px;
}

.defaultStateTxt {
  color: #9BA4BA;
  font-size: 1em;
  line-height: 24px;
  font-weight: 400;
  margin-top: .5rem;
}

.defaultStateVideoRecordBtn {
  margin-top: 1.5rem;
  background-color: #6196FE;
  color: white;
  border-radius: 10px;
  padding: .9rem 1rem;
  border: 0;
}

.defaultStateVideoRecordBtn:hover {
  background-color: #437ff6;
}

.defaultStateVideoRecordBtn:focus {
  outline: none;
}

.introvideoQuestions {
  color: #1C2640;
  font-size: 1.3em;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: .5rem;
}

.candidateVideoContainer {
  border-radius: 20px;
  margin: .5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 24px rgba(97, 150, 254, .1);
}

.videoButtonContainer {
  display: flex;
  margin: 1rem 1rem;
}

.videoBtn {
  background-color: #6196FE;
  box-shadow: 0px 1px 24px rgba(97, 150, 254, .1);
  border: 0;
  position: relative;
  display: flex;
  border-radius: 100%;
  width: 50px;
  line-height: 50px;
  text-align: center;
  z-index: 2;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
}

.videoBtn:hover {
  background-color: #3c7af8;
  box-shadow: 0 0 5px #6196FE; 
  transition: .2s ease-in-out;
}

.videoBtn:focus {
  outline: none;
}

.candidateVideo {
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
  box-shadow: 0px 1px 24px rgba(97, 150, 254, .1);
}

.progressContainer {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.progressbar {
  background: #E6E1E4;
  height: 4px;
  width: 50px;
  border-radius: 3px;
  cursor: pointer;
  color: #FE5819;
}

.progressbar::-webkit-progress-value { 
  background: #FE5819;
  border-radius: 3px;
}

.progressbar::-moz-progress-bar { 
  background: #FE5819;
  border-radius: 3px;
}

.workmapHeading {
  color: #5F6981;
  font-size: 1em;
  font-weight: 400;
  line-height: 24px;
}

.workmapSkillSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 1rem;
}

.workmapSkill {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  margin-right: 1.5rem;
}

.skillRating {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  // border: 7px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 1.2em;
  color: #16182F;
  margin-bottom: .5rem;;
}

.workmapSkillName {
  color: #16182F;
  font-size: 1em;
  font-weight: 500;
  line-height: 18.7px;
}

.aivideoscoreTxt {
  color: #5F6981;
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
}

.videoscoreContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.videoscoreSkill {
  color: #16182F;
  font-weight: 500;
  font-size: 1em;
  line-height: 20px;
}

.videoscorePercent {
  color: #16182F;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 32px;
}

.videoscoreProgressbar1,
.videoscoreProgressbar2,
.videoscoreProgressbar3,
.videoscoreProgressbar4 {
  width: 100%;
  height: .5rem;
}

.videoscoreProgressbar1 > div {
  background-color: #6196FE;
}

.videoscoreProgressbar2 > div {
  background-color: #FFCE54;
}

.videoscoreProgressbar3 > div {
  background-color: #ED5565;
}

.videoscoreProgressbar4 > div {
  background-color: #EC87C0;
}

.videoscoreSection {
  margin-bottom: .7rem;
}

.interestedCandidateText {
  color: #1C2640;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 35px;
}

.messageCandidateBtn {
  display: block;
  width: 100%;
  padding: 1rem;
  background-color: #6196FE;
  color: white;
  border: 0;
  border-radius: 10px;
  font-size: 1em;
  margin-top: 1rem;
}

.messageCandidateBtn:hover {
  background-color: #3c7af8;
  box-shadow: 0 0 5px #6196FE; 
  transition: .2s ease-in-out;
}

.messageCandidateBtn:focus {
  outline: none;
}

.readmoreText {
  color: #6196fe;
  cursor: pointer;
  margin-left: .2rem;
}

.moretoggleOnSkills {
  background: #6196FE;
  border: 0;
  color: white;
  border-radius: 20px;
  padding: .4rem .8rem;
  margin: .2rem .4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.moretoggleOnSkills:hover {
  background: #3d7dfd;
}

.moretoggleOnSkills:focus {
  outline: none;
}

.disable_cursor {
  cursor: not-allowed;
}

.loading {
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1441px) {

  .nameAndImageSection {
    font-size: 1.2em;
  }

  .candidateLocation {
    font-size: 1.1em;
  }

  .candidateResumeAndLinkedin {
    
    svg {
      width: 1.5em;
      height: 1.2em;
    }
  }

  .candidateDetailsOptions {
    font-size: 1.2em;
  }

  .candidateEmailDetails,
  .candidatePhoneNoDetails,
  .candidateSummarySection,
  .candidateSkillsSection {
    font-size: 1.3em;

  }
  
  .skill {
    font-size: .9em;

  }

  .candidateShareProfileTxt {
    color: #080B2E;
    font-weight: 500;
    font-size: 1.7em;
  }

  .shareProfileButton {
    padding: .7rem 1rem;
    font-size: 1.2em;
  }


}

.priceButton {
  background: #6196FE;
  border: 0px;
  border-radius: 10px;
  color: #FFFFFF;
  padding: .5em 1.2em;
}

.priceButtonContainer {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 21.5rem;
  left: 2%;

  p {
    color: #FE5819;
    font-size: 1.3em;
    position: absolute;
    top: 9;
    left: 5%;
  }
}


.learnMoreButton {
  background: #6196FE;
  color: #FFFFFF;
  padding: 1rem;
  font-size: 1em;
  letter-spacing: -.03em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  border-radius: 10px;
  max-height: 1rem;
  letter-spacing: .01em;
}
